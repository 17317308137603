import React from 'react';
import SplitPane from 'react-split-pane';
import './splitter.css';
import Tree from './tree.js';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import {default as ReactDataSheet,DataEditor} from 'react-datasheet';
import './react-datasheet.css'; 
import v_struct from './structure.json';

class PubApp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.options = [];
    this.state.financials=props.financials;
    this.cols = [];
    this.selectedNode=0;
  }
  
  state = {
    disabled: false,
    points: [],
    data: [],
    sh_data:[
      [{ value: "-" }, { value: "-" }],
      [{ value: "-" }, { value: "-" }]
    ],
    financials : [],
    tree : v_struct,
    yaxis: [0,1000],
    unit: ''
  };
  
  pruneTree = (fins) => {
    let xtree=[];
    v_struct.forEach( (v,ia) => {
        var vN = Object.assign({}, v);
        xtree.push(vN);
    });
    let columns=Object.keys(fins);
    let ztree=[];
    xtree.forEach( (v,ia) => {
        //look to see if this name exists
        let treeidx=-1;
        columns.forEach( (t,i) => { //take from original
            if(t===v.name) {
              treeidx=i;
            }
        });
        //if is's not found, then we need to find the children and move them up
        if(treeidx<0) {
            let ch=[];
            xtree.forEach( (c,i2) => {
                if(c.parent===v.index) { //this is a child node
                    ch.push(i2);
                    c.parent=v.parent;
                }
            });
        } else {
            ztree.push(v);
        }
    });
    console.log(ztree);
    this.setState({tree:ztree});
    return fins;

  }
  
  createNaN = (fins) => {
    console.log("v_struct");
    console.log(v_struct);
    let k=fins;

    let f=Object.values(fins);
    let fk=Object.keys(fins);
    let tree=[];

    f.forEach( (v,ia) => {
      //find value in tree
      let treeidx=-1;
      v_struct.forEach( (t,i) => { //take from original
        if(t.name===fk[ia]) {
          treeidx=i;
        }
      });
      var vN = Object.assign({}, v_struct[treeidx]);
      let len=0;
      let numNull=0;
      if(treeidx>=0) {
        vN.bNaN=0; //see if all of them == null, else replace them with 0s
        vN.lname=vN.cname;  //create default lname for each item
        v.forEach( (x,ib) => { //for each element
          if(x===null) {
            numNull++;
            v[ib]=0;
          }
          len++;
        });
        if(numNull===len) {
          vN.bNaN=1;
        }
        if(vN.bNaN===0) { //push them all if private
          tree.push(vN);
        }
      }
    });

    // rework tree, eliminate parents with only one child (or don't exist in private financials)
    let reworkTree = (tree) => {
      let nTree=[];
      tree.forEach( (t,i) => { 
        let ch=[];
        tree.forEach( (c,i2) => {
          if(c.parent===t.index) { //this is a child node
            ch.push(i2);
          }
        });
        if(ch.length===1) { //only one child
          tree[ch[0]].parent=t.parent;
          tree[ch[0]].lname=(<span>
            {t.lname}<span style={{fontSize:'13px'}}>(={tree[ch[0]].lname})</span>
            </span>
          );
          tree[ch[0]].cname=t.cname;
          console.log("deleted:"+t.cname);
        } else {
          nTree.push(t);
        }
      });
      return nTree;
    };
    let nTree=tree;
    for(var i=0;i<4;i++) {
      nTree=reworkTree(nTree);
    }
    fins=k;
    console.log("tree");
    console.log(tree);
    console.log(nTree);
    this.setState({tree:nTree});
    return fins;
  }

  disable = () => {
    this.setState({
        disabled: !this.state.disabled
    });
  };
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.financials !== prevProps.financials) {
      let financials;
      if(!this.props.financials['MarketCapEstimate']) {
        financials=this.pruneTree(this.props.financials);
      } else {
        financials=this.createNaN(this.props.financials);
      }
      this.setState({financials:financials}, function () {
            this.updateCompany(financials);
        });
      
    }
  }
  
  updateCompany = (financials) => {
      console.log("updateCompany:financials");
      console.log(financials);
      this.o=[];
      let v=Object.values(financials);
      v.forEach((f,i) => {
        this.o[i]=this.getUnit(f);
      });
      this.state.tree.forEach((f,i) => {
        if(f.name=="MarketCap") {
          this.onSelect(i);
          return;
        }
      });
  };
  
  findNode = (ky,selectedNode) =>{
    let iNode=-1;
    ky.forEach((f,i) => {
      if(this.state.tree[selectedNode] && f===this.state.tree[selectedNode].name)
        iNode=i;
    });
    return iNode;
  }

  getO = iNode => {
    let out={unit:"",scale:1};
    if(iNode>=0 && iNode<this.o.length) 
      out=this.o[iNode];
    return out;
  }
  
  onSelect = selectedNode => {
    // do something with selected node
    this.selectedNode=selectedNode;
    var sN=this.state.tree[selectedNode];
    var dat=[];
    var cols=[];
    let k=Object.values(this.state.financials);
    var points=[];
    let ky=Object.keys(this.state.financials);
    let e=0;

    let iN=this.findNode(ky,selectedNode);
    dat[0]=[];
    dat[0][0]={disableEvents: true, readOnly: true, value: 'Date'};
    cols[0]='Date';
    let o=this.getO(iN);
    let unit=o.unit;
    let scale=o.scale;
    dat[0][1]={disableEvents: true, readOnly: true, value: (sN.cname+unit)};
    cols[1]=sN.name;
    let idx=2;
    this.state.tree.forEach((f2,i2) => {
      if(f2.parent===sN.index) {
        let iNode=this.findNode(ky,i2);
        if(iNode>=0) {
          dat[0][idx]={disableEvents: true, readOnly: true, value: (this.state.tree[i2].cname+unit)};
          cols[idx]=this.state.tree[i2].name;
          idx++;
        }
      }
    });
    let iNode=this.findNode(ky,selectedNode);
    //find date index
    let dindex=0;
    ky.forEach((v,i) => {
      if(v==="Date") dindex=i; 
    });
    let ColLen=this.state.financials['Date'].length;
    k[iNode].forEach((f,i) => {
        dat[i+1]=[];
        //Date format
        let date=k[dindex][ColLen-i-1].toString();
        date=date.substring(0, 2)+'/'+date.substring(4, 6);
        dat[i+1][0]={disableEvents: true, readOnly: true, value: date }; //date
        //this.getO(iNode).scale
        let r=k[iNode][ColLen-i-1];

        let v=((r/scale)).toFixed(2);
        if(v-Math.round(v)===0)
          v=Math.round(v);
        dat[i+1][1]={value: r.toString(),unit:scale};
        if(sN.name==="MarketCap") {
          dat[i+1][1].eq=1;//market cap
          if(this.state.financials['MarketCapEstimate']) {
            e=this.state.financials['MarketCapEstimate'][ColLen-i-1];
            e=((e/scale)).toFixed(2);
            if(e-Math.round(e)===0)
              e=Math.round(e);
          }
        }
        //find children of selectedNode
        idx=2;

        this.state.tree.forEach((f2,i2) => {
          if(f2.parent===sN.index) {
            let iNode2=this.findNode(ky,i2);
            if(iNode2>=0) {
              let r=k[iNode2][ColLen-i-1];

              let v=((r/scale)).toFixed(2);
              if(v-Math.round(v)===0)
                v=Math.round(v);
              dat[i+1][idx]={value: r.toString(), gray:true,unit:scale};
              idx++;
            }
          }
        });

        //MarketCapEstimate
        if(sN.name==="MarketCap" && this.state.financials['MarketCapEstimate']) {
          //for estimation
          points.push({name:date, usd:v, est:(e).toString()}); //for plot
        } else {
          points.push({name:date, usd:v}); //for plot
        }
    });

    let min=0;
    let max=1;
    points.forEach( (v) => { //maybe include the (M) in the popup somehow
      if((v.usd*1)<min) min=Math.floor((v.usd)*1);
      if((v.usd*1)>max) max=Math.ceil((v.usd)*1);
        //MarketCapEstimate
      if(sN.name==="MarketCap" && this.state.financials['MarketCapEstimate']) {
        if((v.est*1)<min) min=Math.floor((v.est)*1);
        if((v.est*1)>max) max=Math.ceil((v.est)*1);
      }
    });
    //console.log([min,max]);
    this.setState({unit:unit});
    this.setState({yaxis:[min,max]});
    this.setState({points: points.reverse()});
    console.log("points");
    console.log(points);
    this.setState({sh_data: dat});
    this.cols=cols;
  }
  
  getUnit= (u) => {
    var a=[];
    u.forEach((v) => {
      a.push(Math.abs(v));
    });
    var m=Math.max(...a);
    var o={};
    if(m>100000000000) {
      o.unit=' (B)';
      o.scale=10000000000;
    } else if(m>10000000) {
      o.unit=' (M)';
      o.scale=1000000;
    } else if(m>100000) {
      o.unit=' (K)';
      o.scale=1000;
    } else if(m===0) {
      o.unit='';
      o.scale=1;
    } else if(m<=1) {
      o.unit=' (%)';
      o.scale=(1/100);
    } else {
      o.unit='';
      o.scale=1;
    }
    return o;    
  }
  
  onCellChanged = changes => {
    const sh_data = this.state.sh_data.map(row => [...row]);
    changes.forEach(({cell, row, col, value}) => {
      sh_data[row][col] = {...sh_data[row][col], value};
    });
    this.setState({sh_data});
    //now update financials
    var cols=this.cols;
    var financials=this.state.financials;
    changes.forEach(({cell, row, col, value}) => {
      console.log("col");
      console.log(col);
      console.log({...sh_data[row][col], value});
      financials[cols[col]][financials[cols[col]].length-row] = Number(value);
    });
    //add the lambda function here
    this.props.onCellChanged(financials, MC => {
      financials["MarketCap"]=MC;
      this.setState({financials});
      this.onSelect(this.selectedNode);
    });
    //this.setState({financials});
  }

  render() {
    return (
      <div style={{boxShadow: '0 1px 1px rgba(0,0,0,.5) inset',zIndex:'2',position:'absolute',left:'0px',right:'0px',bottom:'31px',top:'36px'}}>
        <SplitPane split="vertical" minSize={50} defaultSize="30%" >
          <div style={{ overflowY: 'auto', width: '100%',height: '100%'}}>
            <Tree elements={this.state.tree} onSelect={this.onSelect} />
          </div>
          <SplitPane split="horizontal" defaultSize="50%">
            <div>
              <div style={{position:'relative', height:'100%', display:'inline-block'}} >
                <div className='rotate' style={{textAlign:'center', color:'#333', position:'absolute', top:'0px', left:'-2px',right:'0px',bottom:'0px'}}>{this.state.unit} USD</div>
                <img alt='' style={{height:'100%'}} src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
              </div>
              <div style={{position:'absolute',top:'0px',bottom:'0px',right:'0px',left:'.5em'}}>
                <ResponsiveContainer>
                  <LineChart data={this.state.points} margin={{ top: 20, right: 20, bottom: 0, left: 0 }}>
                    <Line type="monotone" dataKey="usd" stroke="#8884d8" />
                    {(this.state.points[0] && typeof this.state.points[0].est  !== 'undefined') ? 
                      <Line type="monotone" dataKey="est" stroke="#82ca9d" />
                      :
                      <span></span>
                    }
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="name" />
                    <YAxis type="number" domain={this.state.yaxis}/>
                    <Tooltip />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div style={{textAlign:'left',position:'relative',height:'100%',overflowY:'auto',backgroundColor:'#f5f5f5'}}>
              <div style={{position:'absolute',backgroundColor:'#fff',width: 'calc(100% - 1px)'}} >
                <ReactDataSheet 
                  data={this.state.sh_data}
                  
                  valueRenderer={(cell, i, j) => {
                    let v=cell.value;
                    if(cell.unit) {
                      v=(cell.value*1)/cell.unit;
                      if(v-Math.round(v)===0)
                        v=Math.round(v);
                      v=v.toFixed(2);
                    }
                    return v.toString();
                  }} 
                  /*
                  cellRenderer={props => 
                  {
                    let style={};
                    if(props.style)
                      style=props.style;
                    if(props.col===1)
                      style.borderRight='1px solid black';
                    if(props.cell.eq===1)
                      style.borderRight='2px dashed green';
                    return <td className={props.className} style={style} onMouseOver={props.onMouseOver} onMouseDown={props.onMouseDown} >
                        {props.children}
                      </td>;
                  }} */ 
                  dataEditor={ props => { //no edits!
                      let p={...props};
//                      if(!p.ed) {
//                        p.value=p.cell.oval.toString(); //p.cell.oval
//                      }
//                      p.ed=1;
                      return ( 
                        (this.cols[props.col]=="MarketCap" || this.state.financials['MarketCapEstimate']) ? <span style={{color:'#00f'}}>{props.value}</span> : <DataEditor {...p}/>
                      );
                    } 
                  }
                  
                  onCellsChanged={this.onCellChanged} 
                />
              </div>
            </div>
          </SplitPane>
        </SplitPane>
      </div>
    );
  }
}
export default PubApp;

