/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React, { useState, useRef,useEffect } from 'react';
import SelectSearch from 'react-select-search';
import { Auth } from "aws-amplify";
import ReactGA from 'react-ga';
import {SectorNames,SectorNums,IndustryNames,IndustryNums} from './industryInfo.js';


//import './searchCo.css';


export default function CompanyInfo(props) {
  let infoRef = React.createRef();
  const [companyName, setCompanyName] = useState("");
  const [endDate, setEndDate] = useState("12");
  const [sectorSel, setSectorSel] = useState("");
  const [industrySel, setIndustrySel] = useState("");
  const [Industries, setIndustries] = useState([]);
  const [industryPlaceholder, setIndustryPlaceholder] = useState("Choose Sector First");
  const [reasonsSel, setReasonsSel] = useState("");
  const [reasonsPlaceholder] = useState("Reason for company valuation");
  let reasons=[];
  let reasonNames=["Sale/Merger","Equity/Debt Financing","Employee Stock Ownership","Decision Making","Other"];
  reasonNames.forEach((v,i) => {
    reasons.push({name:v, value: v});
  });
    
  useEffect(() => {
    console.log("useEffect props");
    console.log(props);
    if(props.companies.length>0) {
      setCompanyName(props.companies[0].name);
      setEndDate(props.companies[0].endMonth);
      let sector=Math.floor(props.companies[0].industry/1000);
      setSectorSel(sector.toString());
      setIndustry(sector);
      setIndustrySel(props.companies[0].industry);
      setReasonsSel(props.companies[0].reason);
    }
  },[props.companies]);
  
  async function handleSubmit(event) {
    event.preventDefault();
    let message={name:companyName,endMonth: endDate,industry:industrySel,reason: reasonsSel};
    console.log("handlesubmit props")
    console.log(props);
    let key=props.userKey;
    console.log(key);

    fetch("https://api.valtrace.com/createCompany", {
        method: "POST",
        body: JSON.stringify(message),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': key
        }
      }).then(res => res.text()).then(res => {
        // load company here
        if(window.location.hostname=="www.valtrace.com") {
          ReactGA.event({
            category: 'Value Company',
            action: 'Create Company',
            value: 5
          }); 
        }
        console.log("result from server");
        console.log(res);
        var mres=JSON.parse(res);
        message.id=mres.id; // get the id from the create company lambda function
        props.setCompanies([message]);
        props.loadPrivateCo(companyName);
      });
  }

  function showInfo() {
    infoRef.current.style.display="block";
    let inp=infoRef.current.getElementsByTagName('input');
    inp[0].focus();
  }
  function endInfo(x) {
    infoRef.current.style.display="none";
  } 
  function validateForm() {
    return reasonsSel!="" && sectorSel>0 && industrySel>0 && companyName!="";
  }

  let months=[];
  let monthNames=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  for(let a=0;a<12;a++) {
    months.push({name:monthNames[a], value: (a+1).toString()});
  }
  let Sectors=[];
  SectorNames.forEach((v,i) => {
    Sectors.push({name:v, value: SectorNums[i].toString()});
  });
  
  function setIndustry(val) {
    let Ind=[];
    IndustryNames.forEach((v,i) => {
      if(Math.floor(IndustryNums[i]/1000)==val) {
        Ind.push({name:v, value: IndustryNums[i].toString()});
      }
    });
    setIndustries(Ind);
    setIndustryPlaceholder("Industry");
  }
  
  return (
    <div>
      <style>{`
        .info_table td {
          padding:5px;
          border-bottom:1px solid hsla(0,0%,0%,0.06);
        }
        .companyInfo {
          text-align: center;
          /* May want to do this if there is risk the container may be narrower than the element inside */
          white-space: nowrap;
        }
         
        /* The ghost, nudged to maintain perfect centering */
        .companyInfo:before {
          content: '';
          display: inline-block;
          height: 100%;
          vertical-align: middle;
          margin-right: -0.25em; /* Adjusts for spacing */
        }
        
        /* The element to be centered, can also be of any width and height */ 
        .bl_centered {
          display: inline-block;
          vertical-align: middle;
          width: 300px;
        }  
          
      `}
      </style>

      <div className='companyInfo' style={{overflow:'auto',position:'absolute',left:'0px',right:'0px',bottom:'31px',top:'36px',backgroundColor:'white'}} ref={infoRef}>
        <div className='bl_centered' style={{minWidth:'500px' }}>
          <form onSubmit={handleSubmit} style={{marginBottom:'0'}}>
            <table className="info_table" style={{marginBottom:'0'}}>
              <tbody>
                <tr>
                  <td><span>Company Name: </span></td>
                  <td><input style={{width:'350px'}} value={companyName} onChange={e => setCompanyName(e.target.value)} /></td>
                </tr><tr>
                  <td><span>Fiscal Year End: </span></td>
                  <td>
                    <SelectSearch
                      options={months}
                      name="Month" 
                      placeholder="Month"
                      value={endDate}
                      onChange={setEndDate}
                      search
                    />
                  </td>
                </tr>
                <tr>
                  <td><span>Sector: </span></td>
                  <td>
                    <SelectSearch
                        options={Sectors}
                        name="Sector" 
                        placeholder="Sector"
                        value={sectorSel}
                        onChange={(v) => {setSectorSel(v); setIndustry(v);}}
                        search
                      />
                  </td>
                </tr>
                <tr>
                  <td><span>Industry: </span></td>
                  <td>
                    <SelectSearch
                      options={Industries}
                      name="Industry" 
                      placeholder={industryPlaceholder}
                      value={industrySel}
                      onChange={setIndustrySel}
                      search
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{border: 'none',textAlign:'right'}}><span>Valuation Reason:</span></td>
                  <td style={{border: 'none',position:'relative'}}>
                    <SelectSearch
                      options={reasons}
                      name="reasons" 
                      placeholder={reasonsPlaceholder}
                      value={reasonsSel}
                      onChange={setReasonsSel}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{clear:'both',padding:'5px 0px',width:'100%', textAlign:'center'}}>
              { (props.companies.length==0) ?
                <button type="submit" className='btn' disabled={!validateForm()} >Create Company</button>
              :
                <button type="submit" className='btn' disabled={!validateForm()} >Update</button>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

