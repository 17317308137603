import React from 'react';
import './Homepage.css';
import './App.css';
import companies from '../components/companies.json';
import SelectSearch from 'react-select-search';
import '../components/search.css';
import PubApp from '../components/pub_app.js';
import Login from '../components/login.js';
import { navigate } from "gatsby";
//import logo from "../images/logo_sm.png";
import Dots from "../components/dots.js";
import Guilloche from "../components/guilloche.js";
import { renderToStaticMarkup } from 'react-dom/server';
import Alert from "../components/alert.js";
//import ben from '../images/benjamin-franklin_sm.png';  // for the splash
import { AppContext } from "../libs/contextLib";
import CompanyInfo from "../components/companyInfo.js";
import Footer from '../components/footer.js';
import { Helmet } from "react-helmet";

import Img from "gatsby-image";
import BackgroundImage from 'gatsby-background-image';

class Homepage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.options = [];
    this.animated=0;
    this.companyRef=React.createRef();
    this.mReduceRef=React.createRef();
    this.lReduceRef=React.createRef();
    this.loadRef = React.createRef();
    this.topButtonRef= React.createRef();
    this.orRef= React.createRef();
    this.companyAbout = React.createRef();
    this.splash = React.createRef();
    this.footerText = React.createRef();
    this.mSearchRef = React.createRef();
    this.loginPopup = React.createRef();
    this.topMenu = React.createRef();
    
    //redo naming conventions
    //console.log("Company name changes");
    companies.forEach(c => {
      //eliminate everything after /
      
      let n=c.name[0]; //.toUpperCase()
      let o=n; //original
      let ni=n.search("/");
      if(ni>0) {
        n=n.substring(0,ni);
      }
      n=n.trim();
      //now lets eliminate everything after a comma
      ni=n.search(",");
      if(ni>0) {
        //console.log(n+"->"+n.substring(0,ni));
        n=n.substring(0,ni);
      }
      n=n.trim();
      if(n.charAt(n.length-1)==".") {
        n=n.substring(0,n.length-1);
      }
      //eliminate ends
      var ends=[" INC", " CORP", " CORPORATION", " PLC", " CO", " LP", " LTD"," TRUST", " L P", " GROUP", " HOLDINGS", " COMPANY"];
      for(var a=0;a<2;a++) {
        var r=n.toUpperCase();
        ends.forEach(e => {
          if(r.endsWith(e) && r.charAt(n.length-e.length-1)!="&") {
  //          console.log(n+"->"+n.substring(0,n.length-e.length)+"..."+r.charAt(n.length-e.length-1));
            n=n.substring(0,n.length-e.length);
          }
        });
        n=n.trim();
      }
      //change uppercase to lowercase
      var articles = ["a","an","and","or","as","at","but","by","if","in","nor","of","qua","so","the","yet"];
      var allcap = ["USA","US"];
      if(n==n.toUpperCase()) {
        var sp=n.split(" ");
        var p=[];
        for(var i=0;i<sp.length;i++) {
          var vCount=sp[i].toLowerCase().match(/[aeiou]/gi);
          if(sp[i].length>0) {
            if(allcap.includes(sp[i]) || vCount==null || (i==0 && sp[i].length<4)) {
              p.push(sp[i].toUpperCase());
            } else {
              if(i>0 && articles.includes(sp[i])) {
                //don't capitalize
                p.push(sp[i].toLowerCase());
              } else {
                //console.log("!!!"+n);
                var l=sp[i].toLowerCase().split("");
                l[0]=l[0].toUpperCase();
                p.push(l.join(""));
              }
            }
          }
        }
        n=p.join(" ");
      }
      n=n.trim();
      if(o!=n) {
      //    console.log(o+"->"+n);
      }
      this.options.push({name: n, value: c.symbol}); //c.ticker
    });
  }
  userHasAuthenticated=(state) => {
    this.setState({isAuthenticated:state});
  }
  
  state = {
    disabled: false,
    company: '',
    financials: [],
    text:'',
    isAuthenticated:false,
    companies: [],
    companyButton: 'Value New Company',
    key: 'no',
    activeTab: 0
  };

  setKey=(key) => {
    console.log("key:");
    console.log(key);
    this.setState({key:key});
  }
  setZup=() => {
    this.topMenu.current.style.zIndex=15;
  };
  
  setZdown=() => {
    this.topMenu.current.style.zIndex=10;
  }

  updateCompany = (value) => {
    //reduce opacity of private company tab
    if(this.state.activeTab!=1) {
      var company=this.topButtonRef.current.style;
      company.color='#555';
      company.paddingTop='0';
      company.height='28px';
      company.boxShadow='none';
      company.top='';

      var search=this.mSearchRef.current.getElementsByClassName('select-search__input')[0].style;
      search.boxShadow='';
      search.height='';
      search.color='#000';
      search.top='';
      search.position='';      


//      this.topButtonRef.current.classList.add('aboutButtonH');
      this.state.activeTab=1;
    }   
    const load = this.loadRef.current;
    load.style.visibility='visible';
    this.setState({ company: value});
    this.startAnimation();
    //get the id from the company list
    let id=-1;
    companies.forEach(x => {
      if(x.symbol===value)
        id=x.simfinid;
    });
    //hide the companyInfo
    this.companyRef.current.style.display='none';
    fetch("https://api.valtrace.com/publicFinancials?t="+id)
      .then(res => res.text())
      .then(res => {
        load.style.visibility='hidden';
        var financials=JSON.parse(res);
        this.setState({financials:financials});
      });
  };
  
  reduceTextLen = (text,len) => {
    if(text.length>len) {
      return text.substring(0,len)+"...";
    }  
    return text;
  }

  animationend = (x) => {
    //console.log("animationend");
    //this.companyRef.current.children[0].children[1].style.zIndex=4;  //piss poor way of doing this

  }
  
  startAnimation = (x) => {
    if(this.animated===0) {
      this.animated=1;
      
      this.topButtonRef.current.classList.add('buttonMorph');
      this.topButtonRef.current.classList.remove('topButtonH');
      this.orRef.current.classList.add('orMorph');
      
      const wrapper = this.mReduceRef.current;
      wrapper.classList.toggle('Menu_reduce');
      const wrapper2 = this.lReduceRef.current;
      wrapper2.classList.toggle('Logo_reduce');
      console.log(wrapper);
      var st=this.mSearchRef.current.getElementsByClassName('select-search')[0].style;
      
      st.position='relative';
      st.top='-2px';
      var in2=this.mSearchRef.current.getElementsByClassName('select-search__input')[0].style;
      in2.borderRadius='3px 3px 0 0';
      in2.height='28px';
      
      var company=this.topButtonRef.current.parentNode;
      company.style.position='relative';
      company.style.verticalAlign='';
      company.style.top='8px';
      
      const mSearchRef = this.mSearchRef.current;
      var player=mSearchRef.animate([
        // keyframes
        { top: '43%' }, 
        { top: '0px' }
      ], { 
        // timing options
        duration: 2,
        iterations: 1
      });
      player.onfinish = function(e) {
        mSearchRef.style.top=0;
      };
      
      const footerText = this.footerText.current;
      player=footerText.animate([
        // keyframes
        { height: '20px' }, 
        { height: '0px' }
      ], { 
        // timing options
        duration: 2,
        iterations: 1
      });
      player.onfinish = function(e) {
        footerText.style.height=0;
        footerText.style.paddingTop=0;
      };
      if(window.innerWidth<=800) {
        var im=this.lReduceRef.current.parentNode.style;
        im.width='28px';
        im.overflow='hidden';
        var mS=this.mSearchRef.current.style;
        mS.left='34px';
        mS.transform='';
        mS.width='';
      }
      var sb=this.mSearchRef.current.getElementsByClassName('select-search')[0].style;
      sb.width='32vw';
      sb.maxWidth='250px';
      sb.minWidth='120px';
      var co=this.topButtonRef.current.style;
      co.width='15vw'; //'55px';
      co.maxWidth='12em';
      co.minWidth='55px';
    }
  }
  
  setText=(txt) => {
    this.setState({text:txt});
  }
  
  setCompanies=(companies) => {
    //kill splash
    //this.splash.current.style.display='none';
    console.log("setCompanies");
    console.log(companies);
    this.setState({companies:companies});
    if(companies.length>0) {
      this.setState({companyButton: "Load "+this.reduceTextLen(companies[0].name,20)});
    }
    console.log("Q string");
    console.log(this.props.location.search);
    if(this.props.location.search.search('newCompany')>0) {
      console.log("newCompany direct");
      setTimeout(() =>{this.privateCompanyClick(0)},0);
    }
    
  }
  
  loadPrivateCo=(name) => {
      const load = this.loadRef.current;
      load.style.visibility='visible';
      //+this.state.companies[0].id
      fetch("https://api.valtrace.com/privateFinancials",{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain',
            'Authorization': this.state.key
          }
      }).then(res => res.text())
      .then(res => {
        load.style.visibility='hidden';
        var financials=JSON.parse(res);
        this.setState({financials:financials.companies[0].financials});
        this.companyAbout.current.style.display='inline-block';
      });
      this.companyRef.current.style.display='none';
      this.setState({companyButton: this.reduceTextLen(name,25)});
  }
  
  privateCompanyClick=(e) => {
    //we need to animate to show create company
    console.log("this.state.activeTab");
    console.log(this.state.activeTab);
    console.log("this.animated");
    console.log(this.animated);
    if(this.animated===0 || this.state.activeTab!=0) {
      console.log("reduce search!!!");
      //reduce opacity of public company search
      var search=this.mSearchRef.current.getElementsByClassName('select-search__input')[0].style;
      search.boxShadow='none';
      search.height='28px';
      search.color='#555';
      search.top='-4px';
      search.position='relative';      

      //increase opacity of company tab
      var company=this.topButtonRef.current.style;
      company.color='';
      company.paddingTop='';
      company.height='32px';
      company.top='-2px';
      company.boxShadow='';

      this.state.activeTab=0;
    }
    if(e) {
      if(e.target.classList.contains('aboutButton') || e.target.parentNode.classList.contains('aboutButton')) {
        return 0;  
      }
    }
    if(this.state.companies.length>0) {
      //load in the data for the company
      this.loadPrivateCo(this.state.companies[0].name);
      this.startAnimation();
    }  else {
      //check if we are logged in
      if(this.state.isAuthenticated) {
        this.companyRef.current.children[0].children[1].style.zIndex=3;  //piss poor way of doing this
        this.startAnimation();
      } else {
        //we need to route to signup
        navigate("/createCompany");
      }
    }
    
  }
  onAboutCompany=() => {
    if(this.companyAbout.current.classList.contains('aboutButtonMorph')) {
      this.companyRef.current.style.display='none';
    } else {
      this.companyRef.current.children[0].children[1].style.zIndex=3;  //piss poor way of doing this
      this.companyRef.current.style.display='block';
    }
    this.companyAbout.current.classList.toggle('aboutButtonMorph');
  }
  
  onCellChanged= (data,cb) => {
      var co=this.state.companies[0];
      co.financials=data;
      console.log("co");
      console.log(co);
      fetch("https://api.valtrace.com/updateCompany", {
        method: "POST",
        body: JSON.stringify(co),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': this.state.key
        }
      }).then(res => res.text())
      .then(res => {
        res=JSON.parse(res);
        console.log("res");
        console.log(res);
        cb(res["MarketCap"]);
        console.log("done...updateCompany");
      });
  }

  render() {
      let topStyle= {
        backgroundColor:'#eee',position:'absolute',height: 'calc(100% - 0px)',width:'100%',zIndex:"3"
      };
      const svgString = encodeURIComponent(renderToStaticMarkup(<Dots bsize=".15" />));
      const dataUri = `url("data:image/svg+xml,${svgString}")`;
      let searchStyle= {
        position: 'absolute', 
        top: '43%',
        width:'min(580px,90%)',
        zIndex:11,
        left:'0',
        transform: 'translateX(calc(50vw - min(290px,45%)))',
        textAlign:'center'
      };
      /*  This is the splash that takes up too much bandwidth
      let splashStyle= {
        backgroundImage: "url(" + ben + "), url(" + dollar_background + ")",
        backgroundPosition: 'center,center',
        backgroundColor:'#eee',
        position:'absolute',
        backgroundRepeat: 'no-repeat, repeat',
        backgroundSize: '200px,160px',
        height: 'calc(100% - 0px)',
        width:'100%',
        top:'0px',
        zIndex:"4",
        display:'block'
      };
      */
      const svgString2 = encodeURIComponent(renderToStaticMarkup(<Guilloche />));
      const dataUri2 = `url("data:image/svg+xml,${svgString2}")`;
        
      let headerMenuStyle= {
        paddingTop:'6px',
        fontSize:'13px',
        paddingRight:'min(25px,4.5vw)',
        paddingLeft:'min(25px,4.5vw)',
        paddingBottom: '6px',
        backgroundImage: dataUri2,
        borderRadius: '0 0 0 4px',
        position:'relative'
      };
    return (
      <AppContext.Provider value={[this.state.isAuthenticated, this.userHasAuthenticated]}>
        {/* highlight-start */}
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Calculate your company's value or examine public company financials."/>
          <title>Company Valuation Worksheet</title>
          <link rel="canonical" href="https://www.valtrace.com/" />
          <html lang="en" />
        </Helmet>
        <div className="App hP" style={{position:'absolute',width:'100%',minWidth:'375px',height:'100%'}}>
          <div style={{position:'absolute',height:'31px',bottom:'0',left:'0',right:'0',zIndex:'-6',borderTop: '1px solid #d9d9d9'}}>
            <div className="underlay" style={{opacity:'.70',zIndex:"-10",position:'absolute',top:'0',bottom:'0',left:'0',right:'0'}}>
              <BackgroundImage
                Tag="section"
                fixed={this.props.data.bground.childImageSharp.fixed}
                backgroundColor={`#eee`}
                style={{backgroundRepeat:'repeat', width: '100%', height:'100%',backgroundSize:'160px'}}
              />
            </div>                    
          </div>
          <Alert text={this.state.text} />
          <div ref={this.companyRef} ><CompanyInfo setCompanies={this.setCompanies} loadPrivateCo={this.loadPrivateCo} userKey={this.state.key} companies={this.state.companies} /></div>
          <PubApp financials={this.state.financials} onCellChanged={this.onCellChanged}/>
          {/* This is the splash that takes up too much bandwidth 
            <div ref={this.splash} style={splashStyle}></div>
          */}
          <div ref={this.mSearchRef} style={searchStyle}>
            <div style={{display:'inline-block',verticalAlign:'middle',position:'relative',top:'3px'}}>
              <div ref={this.topButtonRef} style={{padding: '2px 0 4px 0',overflow:'hidden'}} className='topButton topButtonH' onClick={this.privateCompanyClick} >
                {this.state.companyButton}
                <div ref={this.companyAbout} style={{display:'none'}} className='aboutButton aboutButtonH' onClick={ this.onAboutCompany }>
                  <div style={{position: 'relative', bottom: '4px', fontStyle: 'italic'}}>i</div>
                </div>
              </div>
            </div>
            <div ref={this.orRef} style={{margin: '10px', display:'inline-block',fontSize: '75%'}}>or</div>
            <div style={{margin: '0 auto', display:'inline-block'}}>
              <div style={{align:'center'}}>
                <SelectSearch
                  options={this.options}
                  name="ticker" 
                  placeholder="Enter Public Stock Symbol"
                  value={this.state.company}
                  onChange={this.updateCompany}
                  search
                   renderOption={
                    (optionProps, optionData, optionSnapshot) => {
                      return ( 
                        <button className="select-search__option" tabindex="-1" {...optionProps} value={optionData.value}>
                          <span style={{paddingLeft:'5px'}}><span style={{backgroundColor:'#3476e2',color:'white',borderRadius:'3px', padding: '0 3px', marginRight:'5px'}}>{optionData.value}</span><span>{optionData.name}</span></span>
                        </button>
                      );
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div style={topStyle} ref={this.mReduceRef} className='' onAnimationEnd={this.animationend}>
            <div class="underlay" style={{opacity:'.70',zIndex:"-10",position:'absolute',top:'0',bottom:'0',left:'0',right:'0'}}>
              <BackgroundImage
                Tag="section"
                fixed={this.props.data.bground.childImageSharp.fixed}
                backgroundColor={`#eee`}
                style={{backgroundRepeat:'repeat', width: '100%', height:'100%',backgroundSize:'160px'}}
              />
            </div>                    
          </div>
          <div ref={this.topMenu} className="topMenu" style={{zIndex:"10",width:'100%',display: 'flex',position:'absolute'}}>
            <div style={{zIndex:"4",position:'absolute',top:'2px',left:'0px',lineHeight:'34px',marginLeft:'1px'}}>
              <div alt='Valtrace' ref={this.lReduceRef} style={{height:'50px',width:'239px',margin:'10px'}}>
                <Img  style={{height: '100%',width: '100%' }} imgStyle={{height:'100%', width:'100%'}} alt='Valtrace' fixed={this.props.data.logoEl.childImageSharp.fixed} />
              </div>
            </div>
            <div style={{borderTop:'1px solid black',flexGrow:'1',position:'relative'}}>
              <div style={{ boxShadow: 'rgb(0, 0, 0) 0px -5px 2px',position: 'absolute',right: '-1px',height: '50%',top: '0px',left: '-1px',borderRight: '3px double black',borderTop: '3px double black',borderRadius: '0px 5px 0px 0px'}}></div>
              &nbsp;
            </div>
            <div style={headerMenuStyle}>
              <Login setZdown={this.setZdown} setZup={this.setZup} popup={this.loginPopup} {...this.props} setKey={this.setKey} setCompanies={this.setCompanies} setText={this.setText}/>
              <div style={{zIndex:'-1',position:'absolute', height: '60%',borderBottom: '1px solid black', borderLeft: '1px solid black', borderRadius: '0 0 0 5px',right: '0',bottom: '-2px',left: '-2px'}}></div>
            </div>
          </div>
          <div ref={this.loginPopup} style={{position:'absolute',top:'0',right:'0',zIndex:'14'}}></div>
          <Footer setText={this.setText.bind(this)} pHeight='6px' loadRef={this.loadRef}>
            <div ref={this.footerText} style={{textAlign:'center', overflow:'hidden',height: '20px', fontSize:'11px', paddingTop:'1px',color:'white',textShadow:'0 0 3px black'}}>
              <span>By using this site, you are agreeing to its use of cookies.</span>
            </div>
          </Footer>
          <div style={{position:'absolute',bottom:'0'}}></div>
        </div>
      </AppContext.Provider>
    );
  }
}
export default Homepage;
//import images
export const query = graphql`
  query {
    logoEl: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 239) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    bground: file(relativePath: { eq: "100paper_bg2sl.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 160) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }`

