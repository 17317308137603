/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React from 'react';
import plus from './plus.svg';
import minus from './minus.svg';

//if a column is full of NaN, then we don't print it (what if it is a parent?)

class Tree extends React.Component {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.state={elements: props.elements};
    this.state.highlighted=new Array(this.state.elements.length).fill(0);
    this.state.highlighted[0]=1; //start with first one highlighted
    this.state.lowlighted=new Array(this.state.elements.length).fill(0);
    this.state.bopen=[];
    this.callOnSelect=props.onSelect;
  }

  onSelect = (selected) => {
    //console.log(selected);
    let highlighted=new Array(this.state.elements.length).fill(0);
    highlighted[selected]=1;
    this.setState({
      highlighted: highlighted
    });
    this.callOnSelect(selected);
    //console.log("elements");
    //console.log(this.state.elements);
  };
  
  onOpen = (selected) => {
    let name=this.state.elements[selected].name;
    let bopen=this.state.bopen;
    var n = bopen.findIndex(e => e===name);
    console.log("found n:"+n);
    if(n>=0) {
      bopen.splice(n, 1);
    } else {
      bopen.push(name);
    }
    console.log("bopen");
    console.log(bopen);
    this.setState({bopen});
  }
  
  onMouseEnter = (selected) => {
    let lowlighted=new Array(this.state.elements.length).fill(0);
    if(this.state.highlighted[selected]!==1) {
      lowlighted[selected]=1;
    }
    this.setState({
      lowlighted: lowlighted
    });
  }
  onMouseExit = (selected) => {
    let lowlighted=new Array(this.state.elements.length).fill(0);
    this.setState({
      lowlighted: lowlighted
    });
  }
  
  isParents = () => {
    let bparent=new Array(this.state.elements.length).fill(0);
    this.state.elements.forEach( (v,i) => {
      if(v.parent!=null) {
        //find parent index in elements
        this.state.elements.forEach( (v2,i2) => {
          if(v2.index===v.parent)
            bparent[i2]=1;
        });
      }
    });
    return bparent;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.elements !== prevProps.elements) {
      this.setState({elements:this.props.elements});
    }    
  }

  render() {
    const items = [];
    //find the first null, then look for the first item
    //console.log(this.state.bopen);
    let isParent=this.isParents();
    let additems = (level,parent) => {
      for (const [index, value] of this.state.elements.entries()) {
        //get parent name
        if(value.parent===parent) {
          let marg=level*16+"px";
          let c;
          if(this.state.highlighted[index]===1)
            c='#eee';
          else if(this.state.lowlighted[index]===1)
            c='#eff';
          else
            c='#fff';
          var styles = {
            paddingLeft: marg,
            backgroundColor: c
          };
          let icon="";

          if(!this.state.bopen.includes(value.name) && value.parent!=null && isParent[index]===1) {
            //console.log("plus");
            icon=<img src={plus}  style={{width:'.8em',height:'13px',marginRight:'3px'}} onClick={(e) => this.onOpen(index)} alt="+" />;
          }
          if(this.state.bopen.includes(value.name) && value.parent!=null && isParent[index]===1) {
            //console.log("minus");
            icon=<img src={minus} style={{width:'.8em',height:'13px',marginRight:'3px'}} onClick={(e) => this.onOpen(index)} alt="-" />;
          }
          
          //console.log(styles);
          items.push(<div onMouseLeave={(e) => this.onMouseExit(index)} onMouseEnter={(e) => this.onMouseEnter(index)} onClick={(e) => this.onSelect(index)} style={styles} key={index}>
              <span style={{whiteSpace:'nowrap',userSelect:'none'}}>
                {icon}
                <div className="tooltip">
                  {value.cname}
                  <span className="tooltiptext">{value.lname}</span>
                </div>
              </span>
          </div>);
          if(value.parent===null || this.state.bopen.includes(value.name))
            additems(level+1,value.index);
        }
      }
    };
    additems(1,null);
    //console.log(items);
    return (
      <div style={{textAlign:"left", marginTop:"10px"}} >
        {items}
      </div>
    );
  }
}

export default Tree;